<template>
  <div class="container">    
    <div class="server-status mt-4">
      <h3>Server Status for {{ projectName }}:</h3>
      <div :class="['status-indicator', statusClass]">
        {{ statusMessage }}
      </div>
      <div v-if="serverInfo" class="server-info mt-3">
        <p>Project: {{ serverInfo.project }}</p>
        <p>Server ID: {{ serverInfo.server_id }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      status: 'checking',
      statusMessage: 'Checking server status...',
      projectName: '{repo_name}',
      serverInfo: null
    }
  },
  computed: {
    statusClass() {
      return {
        'status-checking': this.status === 'checking',
        'status-healthy': this.status === 'healthy',
        'status-error': this.status === 'error'
      }
    }
  },
  mounted() {
    this.checkServerHealth()
    // Check server health every 30 seconds
    setInterval(this.checkServerHealth, 30000)
  },
  methods: {
    async checkServerHealth() {
      try {
        const response = await fetch('/api/health')
        const data = await response.json()
        
        if (data.status === 'healthy') {
          this.status = 'healthy'
          this.statusMessage = 'Server is running properly'
          this.serverInfo = data
        } else {
          this.status = 'error'
          this.statusMessage = 'Server is not responding correctly'
        }
      } catch (error) {
        this.status = 'error'
        this.statusMessage = 'Cannot connect to server'
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.server-status {
  padding: 20px;
  border-radius: 8px;
  background-color: #f8f9fa;
  display: inline-block;
}

.status-indicator {
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: bold;
  margin-top: 10px;
}

.status-checking {
  background-color: #ffd700;
  color: #000;
}

.status-healthy {
  background-color: #28a745;
  color: white;
}

.status-error {
  background-color: #dc3545;
  color: white;
}
</style>